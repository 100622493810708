import {
  fetchSignInMethodsForEmail,
  getAuth,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import localforage from 'localforage'
import AuditLogsController from '~/controllers/auditLogs'
import { usersStore } from '~/store/users'
import { fb_auth } from '~/services/firebase'
import type { JUser } from '~/models/users/JUser'
import { JAuditLogsAction } from '~/models/auditLogs/JAuditLogs'
import loggerHelper from '~/helpers/LoggerHelper'
import { CACHED_OBJECTS } from '~/config/local-forage'
import WrongAuthenticationMethod from '~/models/errors/WrongAuthenticationMethod'
import startup from '~/helpers/Startup'

const auth = getAuth()
const channel = new BroadcastChannel('logoutChannel')

export const loadUser = async (): Promise<any> => {
  const user: any = auth.currentUser
  if (user) {
    const userId: string = user?.uid ? user?.uid : ''
    return await usersStore().loadUser(userId)
  }
}

export const extractAppSubDomain = () => {
  if (
    import.meta.env.ENV === 'preview' ||
    import.meta.env.VITE_MAIN_DOMAIN_NAME === 'web.app'
  )
    return
  const mainDomainName =
    import.meta.env.VITE_MAIN_DOMAIN_NAME ?? 'localhost:3333'
  const domainName = window.location.host
  if (!domainName.includes(mainDomainName)) return
  let host = domainName.replace(mainDomainName, '')
  if (host === '') return

  if (host[host.length - 1] === '.') host = host.slice(0, -1)

  return host
}

// DON'T USE CLONE DEEP, it breaks update user
export const getUserById = (idUser: string): JUser => {
  return usersStore().findUser(idUser)
}

// export const getOrg = async(userId: string) => {
//   const user = await dbHelper.getDocFromCollection(USER_COLLECTION_NAME, userId)
//   const clientId = user?.client_id
//   const client = await dbHelper.getDocFromCollection(CLIENT_COLLECTION_NAME, clientId)
//   const slug = client?.slug ?? 'default'
//   return slug
// }

export const getUserId = () => {
  const user = fb_auth.currentUser
  if (user) {
    const userId: string = user?.uid ? user?.uid : ''
    return userId
  }
  return ''
}

export const signIn = async (email: string, password: string, useSso) => {
  const methods = await fetchSignInMethodsForEmail(fb_auth, email)
  console.log('methods: ', methods)
  if (!methods?.includes('password') && useSso && methods.length !== 0)
    throw new WrongAuthenticationMethod()

  await signInWithEmailAndPassword(fb_auth, email, password)
  await loadUser()
  AuditLogsController.createNewAudit({ action: JAuditLogsAction.login })
}

export const customSignIn = async (customToken: string) => {
  await signInWithCustomToken(fb_auth, customToken)
  await loadUser()
  AuditLogsController.createNewAudit({ action: JAuditLogsAction.login })
}

export const successLoginInExport = async () => {
  const user = usersStore().user
  await localforage.setItem('currentUser', JSON.stringify(user))
  localStorage.setItem('currentUser', JSON.stringify(user)) // for cypress

  await startup.bootstrap()

  const versionPopup = document.getElementById('version_popup')
  if (versionPopup) versionPopup.remove()
}
export const logout = async (revokeFirebaseToken = true) => {
  try {
    loggerHelper.logInfo(
      `User ${fb_auth.currentUser?.displayName} is logging out`,
    )

    const currentUser = fb_auth.currentUser!

    /* if (revokeFirebaseToken) {
      try {
        await apiHelper.revokeFirebaseToken()
      } catch (e) {
        console.log(e)
        loggerHelper.logWarning(
          `trying to revoke a token that is already revoked : ${e}`,
        )
        console.log('token is already revoked: ', e)
      }
    } */

    await AuditLogsController.createNewAudit({
      action: JAuditLogsAction.logout,
    })

    if (
      currentUser &&
      currentUser?.uid ===
        JSON.parse(await localforage.getItem('currentUser'))?.id
    )
      channel.postMessage('logout')

    await localforage.removeItem('currentUser')
    await localforage.removeItem(CACHED_OBJECTS.LAST_ACTIVITY_TIME)
    await fb_auth.signOut()
  } catch (err) {
    console.error(`Error logging out ${err}`)
  }
}
