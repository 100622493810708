actions:
  alerts: Alertes
  new_goal: Nouvelle cible
  pin_column: Figer
  quick_actions: Plus d'actions
  report_alert: Signaler
  report_alert_description: >-
    Détaillez le signalement et sélectionner les différentes personnes à alerter
    :
  unpin_column: Défiger
alerts:
  add_comment: Commentez sur l'alerte ou ajoutez des détails...
  alert_center: Centre d'incidents
  assigned_to: a assigné l'alerte à
  comment: Commentaire
  get_more: Charger + { count } alerte | Charger + { count } alertes
  information_about_alerts: Plus d'information à propos de l'alerte...
  justification: Justification
  load_more: Plus d'alertes
  minimum_one_justification: Vous devez renseigner une justification
  minimum_one_selected_people: Au moins une personne doit être assignée à l'alerte
  my_interventions: Mes interventions
  new_alert: Nouvelle alerte
  no_justification_provided: Aucune justification n'a été fournie
  no_saved_answers: Aucune réponse n'a été pré-enregistrée
  people_to_alert: 'Personnes ou groupes à alerter:'
  raised_by_me: Levées par moi
  removed_from: a désassigné
  search_placeholder: Rechercher le nom de la fiche
  select_users_to_alert: Sélectionnez la/les personne(s) à alerter
  status_history: Historique des statuts de l'alerte
  to_signal: 'A signaler:'
analysis:
  analysis: Analyse
application_fields:
  all_operations: Toutes les opérations
  all_operations_search: 'Toutes les opérations contenant: { entry }'
  all_products: Tous les produits
  all_products_search: 'Tous les produits contenant: { entry }'
  all_workplaces: Tous les postes de charges
  all_workplaces_search: 'Tous les workplaces contenant: { entry }'
  application_fields: Champs d'application
  charging-station: Poste de charge
  operations: Opérations
  product: Produit
  products: Produits
  reference: Références
  select_all_operations: Sélectionner toutes les opérations
  select_all_products: Sélectionner tous les produits
  select_all_workplaces: Sélectionner tous les postes de charges
  select_operation: Sélectionner une opération...
  select_product: Sélectionner un produit...
  select_workplace: Sélectionner un poste de charge...
  workplaces: Postes de charges
authentication:
  wrong_authentication_method: >-
    Votre utilisateur n'a pas les permissions pour se connecter avec un mot de
    passe, contactez votre administrateur pour plus d'informations.
batch_record:
  batch_record_created: Le document a été créé et attaché aux ordres de fabrication sélectionnés.
  batch_record_creation_error: Une erreur est survenue lors de la création du document.
  add_comment: Ajouter un commentaire...
  batch: OF
  batch_sumup: Voir le résumé de l'OF
  delete_document_from_batch_description: >-
    Êtes-vous sûr de supprimer cette pièce jointe ? Cette action est
    irréversible.
  delete_document_from_batch_title: Supprimer une pièce jointe
  documents: Fiches de l'OF
  information: Informations de l'OF
  last_released_by_title: Dernière libération de l'OF
  modal_release_description: >-
    Vous êtes sur le point de libérer l'OF. Assurez-vous de la conformité totale
    de l'OF avant de valider. Merci de bien vouloir saisir vos identifiants pour
    confirmer.
  modal_release_title: Êtes-vous sûr de vouloir libérer l'OF { name } ?
  new_document: Ajouter une pièce jointe
  release: Libérer
  release_batch_error: Identifiants incorrects.
  release_title: Libération de l'OF
  release_title_sidebar: Libération de lots
  released_batch: OF libéré le { date } par
  update_document: Modifier la fiche
  update_report: Modifier le rapport
  update_attachment: Modifier la pièce jointe
bread_crumb:
  fiches: Fiches
  new_view: Nouvelle vue
  production_orders: Tous les ordres de fabrication
  reports: Tous les rapports
  simulation: Simuler un ordre de fabrication
  templates: Modèles
  views: Toutes les vues
button:
  about: A propos
  back: Retour
  go: ALLER
  home: Accueil
  toggle_dark: Basculer en mode sombre
  toggle_langs: Changer de langue
dashboard:
  empty_dashboard: Aucun  tableau de bord n'est disponible.
  last_30_days: Les 30 derniers jours.
  select: Sélectionnez un tableau de bord
  select_dashboard_placeholder: Sélectionnez
dates:
  day: il y a un jour | il y a {amount} jours
  hour: il y a une heure | il y a {amount} heures
  minute: il y a une minute | il y a {amount} minutes
  month: il y a un mois | il y a {amount} mois
  second: ' il y a une seconde | il y a {amount} secondes'
days:
  daily: Tous les jours
  friday: Vendredi
  monday: Lundi
  saturday: Samedi
  sunday: Dimanche
  thursday: Jeudi
  tuesday: Mardi
  wednesday: Mercredi
display:
  all_columns: Toutes les colonnes
  all_lines: Toutes les lignes
  editable_columns: Colonnes saisissables
  editable_lines: Lignes saisissables
document:
  add_new_block: Ajouter un nouveau bloc
  application_field: Champs d'application
  archive: Archiver la fiche
  starts_with_report_launch: moment du lancement de l'opération
  archive_confirmation_message: Êtes-vous sûr de vouloir archiver la fiche ?
  ask_admin: Veuillez contacter l'administrateur de l'application
  cant_finish_document: Il reste des saisies à effectuer
  cant_finish_document_description: >-
    Vous ne pouvez pas terminer cette fiche sans avoir saisi les cellules
    figurant sur les lignes obligatoires (non-facultatives). Vous pouvez
    néanmoins renseigner "N/A" dans les cellules concernées ou les signaler.
  cant_finish_document_description_mandatory_lines: 'Les lignes suivantes sont obligatoires :'
  capture: Capturer
  category: Catégorie
  cells_missing: '{number} cellule(s) incomplète(s)'
  column_number: Numéro de colonne
  columns: Colonnes
  confirm_archive_in_document:
    description: >-
      Vous êtes sur le point d'archiver cette fiche. Une fois archivé, cette
      fiche ne sera plus disponible pour une utilisation dans le OF. Êtes-vous
      sûr de vouloir continuer ?
    title: Confirmer l'archivage
  confirm_archive_many_documents:
    description: >-
      Vous êtes sur le point d'archiver une fiche. Cette fiche ne pourra plus
      être utilisé dans les OF. | Vous êtes sur le point d'archiver {count}
      fiches. Ces fiches ne pourront plus être utilisé dans les OF.
    title: Confirmer l'archivage
  connect_to_balance: Connecter l'appareil
  create: Nouvelle fiche
  create_new_document: Confirmer la Sauvegarde ?
  create_new_document_message: Vous êtes sur le point de créer la première version de cette fiche.
  create_new_version: Créer une nouvelle version ?
  create_new_version_message: Vous êtes sur le point de changer de version en sauvegardant cette fiche
  created_by: Créé par
  created_on: Créé le
  cycle_of_document: Cycle de fiches
  delete: Supprimer la fiche
  delete_confirmation_message: Êtes-vous sûr de vouloir supprimer la fiche ? Cette action est irréversible.
  device: Poste de charge
  display: Affichage
  display_parameters: Paramètres d'affichage
  document_archive_message: La fiche a été archivé avec succès
  document_archive_title: Fiche archivée
  document_creation_message: La fiche a bien été créé
  document_delete_message: La fiche a été supprimé avec succès
  document_to_model_success: Fiche convertie en modèle avec succès
  document_update_error_message: Une erreur est survenue lors de l'enregistrement de la fiche
  document_duplicate_error_message: Une erreur est survenue lors de la duplication de la fiche
  document_update_message: La fiche a été enregistré avec succès
  enter: Saisir
  exit_without_saving: Quitter sans sauvegarder
  expiration_date: Date d'expiration
  file: Lien
  file_extension_error: L'extension n'est pas autorisée
  finish: Terminer la saisie de la fiche
  finish_confirmation_message: Êtes vous sûr d'avoir terminé votre saisie sur cette fiche ?
  format: Format
  frequency: Fréquence
  get_more: Charger + { count } fiche | Charger + { count } fiches
  get_more_revisions: Charger + { count } révision | Charger + { count } révisions
  input_frequency: Fréquence des saisies
  input_frequency_activate: Activer les saisies fréquentielles
  last_updated_on: Dernière modification le
  lifecycle: Cycle de la fiche
  lines: Lignes
  load_more: Plus de fiches
  load_more_revisions: Plus de révisions
  measures: Mesure
  multiple_selections: Choix multiple
  name: Nom
  new_document: Nouvelle fiche
  no_files: Il n'y a pas de fichiers disponibles
  no_workplace_documents: Aucune fiche disponible sur ce poste de charge
  non_valid: non conforme
  not_found: Aucune fiche trouvée
  note: Note
  notify_operator: Notifier l'opérateur
  number_of_columns: Nombre de colonnes
  order: Ordre
  production_order: Ordre de fabrication
  publish_document: Publier la fiche
  schedule:
    as_soon_as: Dès le
    as_soon_as_the_publication: Dès la publication
    available_from: Disponible dès
    availablity: Disponibilité
    day: jour | jours
    document_is_accessible: La fiche sera accessible
    end: Fin
    end_at: Fin le
    every_day: Tous les {nb} jours
    every_f: Toute | Toutes
    every_m: Tous
    every_month: Tous les {nb} mois
    every_week: Toutes les {nb} semaines
    every_week_article: Toutes les
    every_week_days: Tous les {days}
    every_with_article: Tous les
    every_year: Tous les {nb} ans
    from_date: à partir du {date}
    hour: heure | heures
    minute: minute | minutes
    month: Mois
    no_end: Pas de date de fin
    no_schedule: Pas de répétition
    no_start: Pas de date de début
    no_starting_date: Pas de date de début
    not_recognized: Récurrence pas reconnue
    permanently_available: Disponible en permanence
    publication_date: Date de publication
    recurrence: Récurrence
    recurrence_from: De
    recurrence_to: à
    repeat: Répétitions
    repeat_each: Répéter tous les
    repeat_each_feminine: Répéter toutes les
    set_hour: Définir l'heure
    set_hour_error: L'heure de fin doit être supérieure à celle de début.
    specific_recurrence: Récurrence spécifique
    start: Début
    start_at: Début le
    the_f: la | les
    the_m: le | les
    until_date: et jusqu'au {date}
    week: Semaine | Semaines
    year: Ans | Année | Années
  scheduling: Planification
  scheduling_subtitle: Planifiez l'affichage de la fiche dans l'espace opération
  search: Rechercher une fiche
  select_application_fields: Sélectionner des champs d'application
  simulate: Simuler
  start_date: Date de démarrage
  start_to_create_a_new_document: Commencez à créer une nouvelle fiche
  starting_at: Démarre à
  starting_with: Démarrer au
  state: Etat
  status: Statut
  status_active: Actif
  status_archived: Archivé
  status_deleted: Supprimé
  status_draft: Brouillon
  status_finished: Terminé
  status_in_progress: En cours
  status_in_review: En revue
  status_inactive: Inactif
  status_lot_libéré: Lot libéré
  status_new: Nouveau
  status_production_validée: Production validée
  status_published: Publié
  status_qualified: Qualifié
  status_released: Libéré
  status_revu_quali_en_cours: Revu quali. en cours
  status_select: Sélectionner
  status_being_processed: En cours de traitement
  status_resolved: Résolu
  step_check: OK/NOK
  step_checkbox: Case à cocher
  step_file: Fichier
  step_link: Lien
  step_measure: Mesure
  step_number: Nombre
  step_calculator: Calculatrice
  step_options_list: Liste de choix
  step_photo: Photo
  step_photo_no_camera_access: >-
    L'accès à la caméra est désactivé. Veuillez autoriser l'accès dans les
    paramètres de votre navigateur.
  step_photo_no_devices_found: Aucun appareil n'a été trouvé. Veuillez connecter une caméra USB.
  step_photo_preview_picture: Aperçu de la photo
  step_photo_take_picture: Prendre une photo
  step_photo_add_picture: Ajouter une photo
  step_photo_take_picture_again: Reprendre la photo
  step_photo_take_picture_description: >-
    Utilisez votre caméra pour prendre une photo. Cliquez sur "Prendre" pour
    sauvegarder la photo et prévisualisez-la pendant 2 secondes avant que la
    modal se ferme automatiquement.
  step_photo_update_picture: Modifier la photo
  step_photo_update_picture_description: >-
    Vous pouvez mettre à jour votre photo existante en utilisant la caméra.
    Cliquez sur "Prendre une photo" pour sauvegarder la nouvelle photo, elle
    sera prévisualisée pendant 2 secondes et la pop-up se fermera
    automatiquement, remplaçant ainsi l'ancienne photo.
  step_text: Texte
  step_time: Heure
  steps_linked_to_template: Des blocs sont déjà liés à un modèle
  title: Mes fiches
  to_fill: A saisir
  trigger: Déclenchement
  trigger_element: Élément déclencheur
  type: Type
  type_auto: Automatique (NIR)
  type_manual: Manuel
  type_readonly: Lecture seule
  uncertain: incertain
  unlink_steps: Dissociez d'abord les blocs déjà importés d'un modèle.
  unsaved_changes_description: Toute modification sera perdue si vous n'enregistrez pas cette fiche
  unsaved_changes_title: Cette fiche n'est pas sauvegardée
  update_research: Modifier ma recherche
  updated_at: Modifié le
  updated_by: Modifié par
  updated_on: Modifié le
  use_as_template: Utiliser comme modèle
  valid: conforme
  version: Version
  version_reason_add: Ajouter une note sur la nouvelle version de la fiche
  version_reason_error: Vous devez justifier la nouvelle version de la fiche.
  workflow: Cycle de vie
  you_have_completed: Vous avez complété
error:
  email_already_in_use: Email déjà utilisé
  nfc_code_policy_not_valid: Le code doit comporter 16 caractères
  not_same_old_password: Le mot de passe ne peut pas être identique à l'ancien mot de passe
  password_policy_not_valid: >-
    Le mot de passe doit avoir au moins 8 caractères et inclure des lettres
    minuscules et majuscules
  passwords_not_match: Les mots de passe ne correspondent pas
export:
  at: à
  attachments_of_document: Les_pièces_jointes_de_la_fiche
  change_target: changement de cible
  columns_count: Nombre de colonnes
  comment: Commentaire
  created_at: Créé le
  created_by: Créé par
  description: Description
  document_export_error: Une erreur s'est produite pendant la préparation de votre fiche.
  download_error: Une erreur s'est produite pendant la connexion au serveur.
  download_is_starting: Votre téléchargement va commencer dans un instant...
  export_document_as_pdf: Télécharger la fiche en format pdf
  export_type: Type d'export
  captured_data: Données capturées entre
  goal: Goal
  historic_value: Saisie antérieur
  justification: Justification
  master_session_status_history: Historique des statuts de l'OF
  max: Max
  min: Min
  modified_at: Modifié le
  modified_by: Modifié par
  name: Nom
  operator: Operateur
  report_title: Titre du rapport
  status: Statut
  status_history: Historique des statuts du rapport
  step_type: Type
  tags: Tags
  tool: Moyen de mesure
  type: Type
  unit: Unité
  update_date: Modifié le
  value: Valeur
  workplaces: Postes de charge
filters:
  activities: Activité
  add: Filtrer les rapports
  alternative_workplaces: Postes de charge alternatifs
  assignation: Assignation
  assignee: Personne assignée
  model: Formulaire d'incident
  incident_form: Formulaire d'incident
  at_least_one_display_column: Au moins une option doit être choisie
  blocker: Bloquant
  blockers: Bloquants
  categories: Catégorie de fiche
  choose_filter: Choose a filter
  date: Date
  display: Affichage
  display_columns: Colonnes affichées...
  documents: Fiches
  filter: Filtrer
  filter_by: Filtrer par
  go_back: Retour aux choix de filtres
  group_by: Grouper
  last_14_days: 14 derniers jours
  last_24_hours: 24 dernières heures
  last_3_days: 3 derniers jours
  last_7_days: 7 derniers jours
  last_month: Le mois dernier
  machines: Postes de charge
  models: Templates
  no_grouping: Aucun
  no_product: Aucun produit
  no_workplace: Aucun poste de charge
  operation: Opération
  operations: Opération
  parent: Parent
  product: Produit
  production_orders: Ordre de fabrication
  products: Produit
  recurrence: Récurrence
  raised_by: Lancé par
  remove_all: Tout effacer
  role: Rôle utilisateur
  search: Rechercher un filtre...
  select_a_date: Sélectionner une date
  site: Site
  status: Statut
  teams: Équipe
  templates: Modèles
  title: Filtres
  today: Aujourd'hui
  users: Opérateur
  work_order: Ordre de fabrication
  workplace: Poste de charge
  workplaces: Poste de charge
  yesterday: Hier
firebase:
  too_many_attempts: >-
    Votre compte est temporairement désactivé, vous avez effectué trop de
    tentative. Veuillez réinitialiser votre mot de passe
  unexpected_error: Une erreur inattendue est servenue. Veuillez rafraîchir la page
  user_disabled: Votre compte a été désactivé
  weak_password: Le mot de passe doit faire au moins 6 caractères
  wrong_actual_password: Mot de passe actuel incorrect
  wrong_email: Adresse e-mail incorrecte
  wrong_password: Mot de passe incorrect
format:
  fixed: Fixe
  formula: Formule
  quantity: Quantité
  unique: Unique
  unlimited: Illimité
global:
  automatic_machine: Machines automatiques
  validation_error: "Erreur lors de l'importation de votre fichier, veuillez vérifier vos données"
  updated_by: Modifié par
  modify: Modifier
  display_NC_only: Afficher seulement des colonnes avec des non-conformités
  step: Bloc
  steps: Blocs
  cell: Cellule
  history_annex: Annexe Historique
  pictures_annex: Annexe des blocs photos
  column: Colonne | Colonnes
  account_and_access: Utilisateurs
  document_attachment_annex: Annexe des documents attachés au rapport
  step_attachment_annex: Annexe des documents attachés aux étapes
  actions: Actions
  add: Ajouter
  result: Résultat
  next: Suivant
  add_a_attachment_file: Ajouter une pièce jointe
  add_user: Ajouter un utilisateur
  additional_informations: Informations supplémentaires
  alert: Alerte | Alertes
  alerts: Demandes de support
  alerts_success: Alerte créé avec succès !
  all: Tout
  ask_site_admin: Veuillez contacter l'administrateur de ce site
  no_rights: Vous n'avez pas de droit
  allTeams: Toutes les équipes
  all_the_documents: Toutes les fiches
  all_the_of: Tous les of
  all_the_templates: Tous les modèles
  analyses: Analyses
  and: et
  application_fields: Champs d'application
  archive: Archiver
  are_you_sure_you_want_to_delete_user: Êtes-vous sûr de vouloir supprimer cet utilisteur
  are_you_sure_you_want_to_logout: Êtes-vous sûr de vouloir vous déconnecter
  at: à
  attachment: Pièce jointe
  attachments: Pièces jointes
  attention_any_unsaved_document_will_be_lost: Attention toute fiche non enregistré sera perdu !
  audit_logs: Journal des connexions
  back_home: Retourner à la page d'accueil
  beforeOrAfter: Avant ou aprés
  both_passwords_dont_match: Les deux mots de passe ne correspondent pas
  bread_crumb:
    fiches: Fiches
    modeles: templates
    simulation: simulation
  cancel: Annuler
  change_language_to_apply: >-
    Il est fortement recommandé de rafraîchir la page pour appliquer les
    changements
  change_password: Changer le mot de passe
  choice: choix
  click_button_reload: Cliquer sur "Recharger" pour y accéder.
  client: Client
  clients: Clients
  close: Fermer
  complete: Terminer
  confirm: Confirmer
  confirm_import: Confirmer l'import des données
  confirm_import_desc: >-
    Importer ce fichier va actualiser les données clients précédemment importées
    dans JUNO
  confirm_modification: Confirmer les modifications ?
  confirm_password: Confirmer le mot de passe
  confirm_password_validation_label: Les deux mots de passe
  confirmation_password: Le mot de passe de confirmation
  content: Contenu
  copy_of: Copie de
  create: Créer
  created_at: Créé le
  created_by: Créé par
  created_on: créé le
  current_language: fr
  current_password: Mot de passe actuel
  dashboard: Tableau de bord
  days: jours
  define_as_a_template: Définir comme modèle
  delete: Supprimer
  description: Description
  disconnected: Vous avez été déconnecté(e)
  disconnected_for_inactivity: En raison d'une inactivité prolongée, vous êtes déconnecté(e).
  display: Afficher
  document: Fiche | Fiche | Fiches
  document_parameters: Conception
  documents: Fiches
  download: Télécharger
  download_as_pdf: Télécharger en pdf
  duplicate: Dupliquer
  duplicate_on_destination_site: Site de destination
  duplicate_no_language_selected: Aucune langue sélectionnée
  duplicate_no_site_selected: Aucun site sélectionné
  duplicate_and_translate: Traduire le document
  duplicate_on_another_site: Dupliquer sur un autre site
  duplicate_on_another_site_successful: La fiche a été dupliquée avec succès sur le site de destination
  edit: Editer
  element: Aucun élément | 1 élément | {n} éléments
  element_not_found: Aucun élément n'a été trouvé
  email: E-mail
  empty_list: Aucun enregistrement trouvé
  enriched: Enrichi
  enriched_export: Export enrichi
  error: Erreur
  export: Exporter
  files: Fiches du poste
  filter: Filtre | Filtres
  finish_document: Terminer la fiche
  finish_entry: Finir ma saisie
  finished: Terminé
  first_name: Prénom
  for: Pour
  forbidden: Accès interdit à cette page
  forbidden_message: Contactez votre administrateur pour vérifier l'accès
  force_reset_password: >-
    Vous vous connectez pour la première fois, veuillez réinitialiser votre mot
    de passe
  from: à partir de
  ftp_parameters: FTP
  group: groupe | groupes
  hello: Bonjour
  history: Historique
  id: Id
  if: Si
  import: Importer
  import_export_parameters: Importer/Exporter
  inProgress: en cours
  in_progress: En cours
  instructions: Consignes
  integration_parameters: Intégration
  integrations: Integrations
  justification: Justification
  label: Label
  last_modification: Dernière modification
  last_name: Nom de famille
  library: Bibliothèque
  link: Lien
  list: Liste
  loading: Chargement...
  login: Connexion
  logout: Se déconnecter
  mandatory: Obligatoire
  mark_as_read: Tout marquer comme lu
  media_library: Médiathèque
  minutes: minutes
  modeles: templates
  monitoring: Supervision
  month: Mois
  my_dashboards: Mes Tableaux de bord
  name: Nom
  name_document: Nom de la fiche
  nb_of_boxes: Nombre de boîtes
  need_reset_password: Avez-vous besoin de réinitialiser votre mot de passe ?
  network_error: Pas de connexion internet
  new: Nouveau
  new_password: Nouveau mot de passe
  new_version: Une nouvelle version de Juno est disponible
  next_check_at: Contrôle à effectuer sur la fiche {reportName}, sur l'étape {step}
  'no': Non
  no_value: Aucune valeur
  notification_parameters: Notifications
  number_of_steps: '{number} étape | {number} étapes'
  ok: OK
  operation: Opération
  operator_interface: Interface opérateur
  optional: Facultatif
  or: Ou
  other: Autre
  out_of: sur
  parameters: Paramètres
  parent: Parent
  password: Mot de passe
  password_expired: Votre mot de passe a expiré, veuillez le mettre à jour
  password_has_changed: Votre mot de passe à été modifié
  password_update_error_message: Une erreur s'est produite lors de la modification du mot de passe
  people: '{amount} personnes'
  per_page: par page
  percentage: pourcentage de tolérance
  performances: Performances
  permissions: Rôles et permissions
  picture: Image
  please_select_type: Veuillez sélectionner un type
  preview: Aperçu
  product: Produit
  publish: Publier
  range: intervalle
  reason: Raison
  refresh: Actualiser
  reload: Recharger
  remove_attachment: Supprimer le fichier joint
  report: Rapport | Rapports
  reports: Rapports
  reset_email_sent: >-
    Un e-mail de reinitilisation de votre mot de passe a été envoyé. Veuillez
    cliquer sur le lien lorsque vous l'obtenez
  resolved: Résolu
  restore: Récupérer
  revision: Révisions
  role: Rôle
  roles: Rôles
  row: Ligne | Lignes
  save: Enregistrer
  save_preferences: Enregistrer les préférences
  search: Rechercher
  search_assignee: Assigner à
  search_means_of_measurement: Rechercher...
  search_unit: Rechercher une unité...
  seeAll: Voir tout
  seeDescription: Voir description
  select: Sélectionner
  selectTeam: Sélectionnez une équipe
  select_tab: Sélectionnez un onglet
  select_type: Sélectionner un type
  selected_documents: '{n} fiche sélectionnée | {n} fiches sélectionnées'
  selected_documents_from_to: '{from}-{to} sur un total de {total} fiches'
  selected_lookups_from_to: '{from}-{to} sur un total de {total} données'
  selected_products_from_to: '{from}-{to} sur un total de {total} produits'
  selected_workplaces_from_to: '{from}-{to} sur un total de {total} postes de charge'
  selected_production_orders_from_to: '{from}-{to} sur un total de {total} ordres de fabrication'
  selected_production_orders: '{n} rapport sélectionné | {n} rapports sélectionnés'
  send: Envoyer
  send_reset_email: Envoyer un e-mail de récupération
  settings: Réglages
  sign_out: Déconnexion
  simple_export: Export simple
  simulation: Simulation
  site_management: Gestion du site
  sites: Sites
  complementary_sites: Sites complémentaires
  start: Commencer
  startTask: Commencer une tâche
  status_management: Gestion des statuts
  steps_parameters: Paramètres des blocs
  success: Succès
  summary: Synthèse
  switch_to_operator: Espace production
  switch_to_supervisor: Espace supervision
  switch_user: Changer d'utilisateur
  switch_user_description: >-
    Vous êtes sur le point de changer de compte utilisateur. Après confirmation,
    vous serez redirigé vers la page de connexion et vous devrez saisir les
    identifiants d'un autre utilisateur pour revenir à cette page.
  switch_user_title: Changer l'utilisateur
  tags: Étiquette
  template: Modèle
  template_messages_parameters: Modèles de messages
  templates: Modèles
  then: Alors
  time: Time
  title: Titre
  to_consult: A consulter
  today: Aujourd'hui
  total_of_controles: Total de contrôles
  total_parts: Total de pièces
  total_report: Rapports totaux
  understood: J'ai compris
  unknown: Inconnu
  unlink_step: Voulez-vous vraiment dissocier ce bloc de son modèle ?
  unlink_step_message: >-
    Éditer ce bloc implique qu'il ne pourra plus être mis-à jour si vous rompez
    le lien avec le modèle suivant :
  update: Mettre à jour
  user: Utilisateur
  user_session_management: Gestion de la session utilisateur
  users: Utilisateurs
  validate: Valider
  view: Voir
  warning: Attention
  workflow_parameters: Cycle de vie
  workflows: Flux de fiche
  workplace: Poste de travail
  'yes': Oui
  you_are_offline: Vous êtes actuellement hors ligne. Merci de vous reconnecter.
  your_profile: Votre profil
global_fields:
  created_by: created by
  created_on: created on
groups:
  created_at: Date de création
  load_more_groups: Plus de groups
  name: Nom
  no_result: Aucun résultat ne correspond à votre recherche.
  placeholder: Sélectionnez les personnes ou les groupes à alerter
  users: Utilisateurs
history:
  and_more: et {amount} de plus...
  assigned: a assigné l'alerte à
  commented: a commenté
  created: a signalé l'alerte
  history: Historique
  updated: a changé le statut de l'alerte
  started: la demande d'assistance est dans l'état
incidents:
  resolved_automatically: Cet incident a été résolu automatiquement grâce au formulaire.
  raised_incident: A soulevé une nouvelle demande d'assistance.
  collected_answers: Réponses collectées
  add_question: Nom de l'étape...
  add_answer: Nom du choix de réponse...
  help_request: Demande de support
  incidents_form: Formulaire d’incident
  add_comment: Commentez sur la demande de support ou ajoutez des détails...
  add_comment_on_incident: Merci d'ajouter un commentaire pour nous aider à comprendre en détail...
  unsaved_changes_title: Cette formule n'est pas sauvegardée
  unsaved_changes_description: Toute modification sera perdue si vous n'enregistrez pas ce formulaire
  last_question: Ce formulaire vous a-t-il aidé à résoudre votre problème ?
  help_form_sent_title: Bien envoyé
  help_form_sent_content: Votre demande de support a été envoyée avec succès.
  no_model_available: Aucun modèle de support n'est disponible pour le moment.
  modify_data_error: Tous les champs ne sont pas remplis correctement.
  modify_data_success: Le formulaire à bien été enregistré
  missing_response: Réponse manquante
insights:
  valid_blocks: Blocs valides
  valid_control_points: Points de contrôles valides
  valid_controls: Contrôles valides
instructions:
  incidents: Demandes de support
  archive: Archiver
  create_error: Une erreur s'est produite
  create_error_msg: Une erreur s'est produite veuillez réessayer plus tard
  created_successfully: Instruction(s) créée(s) avec succès
  export: Exporter
  instructions_transfer: Passage de consignes
  modify_instructions: Modifier
  new_instruction: Nouvelle consigne
  no_instructions_found: Aucune consigne n'a été trouvée
  save_instruction: Sauvegarder
  selected: sélectionné | sélectionnés
  unarchive: Désarchiver
  update_error: Une erreur s'est produite
  update_error_msg: Une erreur s'est produite veuillez réessayer plus tard
  updated_successfully: Instruction(s) modifiée(s) avec succès
  write_instructions: Écrivez vos instructions pour le prochain opérateur...
intro:
  aka: Aussi connu sous le nom
  desc: Modèle de démarrage de Vite Opinionated
  dynamic-route: Démo d'itinéraire dynamique
  hi: "Salut, {nom}\_!"
  whats-your-name: Quel est ton nom?
jobs:
  choose_workplace: Choisissez votre poste de charge
  choose_workplace_desc: >-
    Sélectionnez votre poste de charge afin de faire apparaître les ordres de
    fabrication
  create_production_order: Créer cet ordre de fabrication
  current_jobs: Tableau de bord
  last_10_days: OF des 10 derniers jours
  last_30_days: OF des 30 derniers jours
  no-result: Pas de résultat pour cette recherche
  no_product_order_selected: Aucun ordre de fabrication trouvé! cliquez pour choisir un produit
  not_found: Aucun ordre de fabrication ouvert
  number: Numéro d'OF
  operation: Numéro d'opération
  production_order_doesnt_exist: Cet ordre de fabrication n'existe pas !
  select-of: Veuillez sélectionner un numéro d'OF
  select_product: Veuillez sélectionner un produit
  start_new: Démarrer un ordre de fabrication
  start_new_job_desc_no_jobs: >-
    Ouvrez un ordre de fabrication en cliquant sur le bouton en haut à droite de
    l'écran
  status_finished: Terminé
  status_finished_message: L'ordre de fabrication a été finalisé
  status_in_progress: En cours
  status_new: Nouveau
languages:
  de: Allemand
  en: Anglais
  english: Anglais
  es: Espagnol
  fr: Français
  french: Français
  german: Allemand
  it: Italien
  italian: Italien
  spanish: Espagnol
media_library:
  add: Importer un fichier
  archive_confirmation_message: Êtes-vous sûr de vouloir archiver ce fichier ?
  delete: Archiver un fichier
  delete_confirmation_message: vous etes sur que vous voulez supprimer ce fichier
  failed_upload: Certains fichiers n'ont pas été télécharger à cause d'une erreur
  file_update_error_message: Une erreur est survenue lors de la connexion au serveur, veuillez réessayer.
  media_parameters: Médiathèque
  no_data: >-
    Aucun fichier n'a été importer, cliquer sur le button importer pour ajouter
    vos fichiers
  no_file_yet: Ajouter vos fichiers ici pour les importer dans JUNO
  no_search_result: Aucun fichier ne correspond a votre recherche
  no_archived_files: Pas de fichiers archivés
  search_file: Rechercher sur votre ordinateur
  show: Activé
  staged_file: fichier sélectionné
  staged_files: '1 fichier a été séléctionné | %{count} fichiers séléctionnés '
  tab_archived: Archives
  tab_files: Fichiers disponibles
  update_file: Remplacer le fichier
  upload: Importer
  upload_files: Importer des fichiers
  uploaded_files: >-
    1 fichier a été importé avec succès | %{count} fichiers ont été importé avec
    succès
mes:
  top_5_products: Top 5 des produits
  flop_5_products: Flop 5 des produits
  charts:
    synthetic_rate_of_return: Taux de rendement synthétique
    availability_rate: Taux de disponibilité
    quality_rate: Taux de qualité
    performance_rate: Taux de performance
    percentage_of_use: "% d'utilisation"
    stop_pareto: Pareto des arrêts
    scrap_pareto: Pareto des rebuts
    slowdown_pareto: Pareto des ralentissements
  my_performance: Mes performances
  scrap: rebut | rebuts
  signal_scrap: Signaler un rebut
  scrap_quantity: Quantité rebutée
  production_rate_expected: Cadence attendue
  add_production_rate_expected: Ajouter une cadence attendue
  number_of_pieces: Nombre de pièces
  pieces: pièces
  show_nc_only: Uniquement les NCs
  no-nc-today: Pas de non conformité aujourd'hui
  no-nc-in-selected-date: Pas de non conformité dans la date choisie.
  machine_status_between: L'état du poste de charge entre %{start} et %{end}
  associate-po-to-my-machine:
    description: >-
      Veuillez saisir les informations requises pour associer un OF à votre
      poste de charge et voir la quantité prévue à produire.
    production-order-number-label: Numéro de l'OF
    production-order-number-placeholder: Saisir le numéro de l'OF...
    quantity-label: Quantité à produire
    quantity-placeholder: Saisir la quantité à produire...
    save: Associer l'OF
    title: Associer un OF à mon poste de charge
  handle_production:
    start_on_current_session: Commencer la production sur le poste de charge
    confirm_current_production: Confirmer que l'OF actuel est en cours de production sur le poste de charge
    confirm_finished_production: Confirmer que l'OF actuel n'est plus en cours de production sur le poste de charge
    unlink_env: "Votre production sera découplée de l'environnement de travail suivant :"
    link_env: "Votre production sera liée à l'environnement de travail suivant :"
    relink_env: 'et sera rattachée à cet environnement de travail :'
    start: Commencer ma production
    in_progress: Production en cours
    stop: Stopper ma production
  available: Disponible
  batch: Lot
  digram-trs: Diagramme en cascade TRS
  machine: Poste de charge
  machine-has-no-data: Le poste de charge n'a pas de données
  machines-indicators: Indicateurs par poste de charge
  net-production-time: Temps net
  no-machines-found: Aucun poste de charge trouvée
  opened-time: Temps d'ouverture
  paretor-unknown-stoppage: Pareto des causes d'arrêt
  performances: Performances
  oee: TRS
  compared_to: Comparé à
  availability: Disponibilité
  performance: Performance
  quality: Qualité
  po: OF
  machines: Postes de charge
  date: Date
  production: Production
  production-indicators: Indicateurs de production
  production-monitoring: Suivi de production
  production-rate: Cadence de production
  production-rate-delta: Ecarts de cadence
  production-time: Temps de prod.
  quality-waste: Rebuts
  production_target_line_chart: Objectif de production
  setting: Paramétrage
  setting-shutdown-reasons: Paramétrage des raisons d'arrêt
  all-machines: Tous les postes de charge
  settings:
    modal:
      color: Couleur du paramétrage
      edit:
        title: Modifier la raison d'arrêt
      machine: Poste de charge
      new:
        title: Nouvelle raison d'arrêt
      reason: Titre de la raison d'arrêt
      reason-placeholder: Saisir un titre...
      stop-machine-reason: Sélectionner un poste de charge
    table:
      color: Couleur
      machine: Poste de charge
      status: Statut
      title: Titre
      produced-parts: Pièces produites
      oee: TRS (%)
      availability: Disponibilité (%)
      performance: Performance (%)
      quality: Qualité (%)
  total-time: Temps total
  unknown-stoppage: Arrêt non qualifié
  no-available-data: Pas de données disponibles
  full-screen: Plein écran
  exit-full-screen-mode: Quitter le mode plein écran
  root_causes:
    title: Mes causes racines
    demo:
      rc_1_title: Mauvais réglage machine
      rc_2_title: Changement de gamme
      rc_3_title: Mauvais nettoyage de la machine
      rc_4_title: Incompatibilité des composants
      rc_5_title: "Défaut d'approvisionnement"
      rc_children_1_title: Erreur de calibration
      rc_children_2_title: Vitesse de production mal réglée
monthes:
  april: Avril
  august: Août
  december: Décembre
  february: Février
  january: Janvier
  july: Juillet
  june: Juin
  march: Mars
  may: Mai
  november: Novembre
  october: Octobre
  september: Septembre
not-found: Non trouvé
notifications:
  activate_feature: Activer ou désactiver
  alert_type: Notifications d'alerte
  controls_needed: contrôle à effectuer | contrôles à effectuer
  go_to_task: Accéder à la tâche
  machine_stopped: >-
    Arrêt de poste de charge à %{time}. Merci de qualifier immédiatement la raison de
    cet arrêt.
  qualify_stop: Qualifier l'arrêt
  reminder_type: Notifications de rappel
  undefined_document: Fiche sans nom
  view: Voir la notification
operator:
  all_workplaces: Tous les postes de charges
  all_workplaces_page: Tous les postes de charge
  filter_by_workplaces: Filtrer par poste de charge
  no_workplaces_recently_opened: Vous n'avez pas encore ouvert de poste de charge
  qualify:
    modal:
      comment: Commentaire
      comment-placeholder: Ajouter un commentaire...
      description: >-
        Un arrêt de production a été détecté sur le poste de charge %{machine} à
        %{time}. Veuillez fournir les détails suivants pour qualifier
        l'événement d'arrêt.
      optional: Optionnel
      reason: Raison d'arrêt
      reason-placeholder: Sélectionner une raison d'arrêt...
      title: Qualifier l'arrêt poste de charge
  requalify:
    modal:
      description: >-
        Veuillez fournir les informations suivantes pour requalifier cet
        événement d'arrêt.
      reason: Raison de requalification
      reason-placeholder: Sélectionner une raison de requalification...
      title: Requalifier l'arrêt poste de charge
  search_workplace: Rechercher un poste de charge
  search_workplace_message: Affinez votre recherche pour faire apparaître les poste de charges associés
  sel: Rechercher un poste de charge
  select_all_workplaces: Sélectionner tous les postes de charge
  sidebar:
    machine-is-available-again: Poste de charge de nouveau disponible
    machine-is-off: Poste de charge éteinte
    machine-monitoring: Suivi des postes de charge
    nir: Surveillance NIRs
    qualify: Qualifier
    requalify: Requalifier
    workplace-documents: Fiches de poste
  sidebar_nir_monitoring: Surveillance NIR
  sidebar_operations_support: Support opérationnel
  sidebar_workplace_documents: Fiches de poste
  workplace: Poste de charge
  workplace_doesnt_exist: Ce poste de charge n'existe pas
  workplaces_recently_opened: Postes de charges récemment ouverts
pages:
  alerts: Toutes les demandes de support
  alerts_list: Toutes les demandes de support
  analytics: Analyses
  document: Fiches
  form: Fiches
  history: Historique
  library: Bibliothèque
  media_library: Médiathèque
  monitoring: Supervision
  reports: Tous les rapports
  settings: Paramètres
  template: Modèles
  views: Vues
profil:
  description: Gérez toutes les informations personnelles liées à votre profil JUNO
  email: Adresse email
  language: Langue
  my-profile: Mon profil
  not_specified: Non spécifié
  security: Sécurité
  trigram: Identifiant usine
repetition:
  add: Créer une répétition
  additional_filter_nature: Nature
  create_formula: Créer une formule
  create_sampling: Créer un échantillon
  created_successfully: La répétition a été créée avec succès
  created_successfully_message: vous pouvez commencer à utiliser la nouvelle répétition dans vos fiches
  creation_error: Une erreur inattendue s'est produite
  frequency_list: Liste des fréquences
  creation_error_message: >-
    Une erreur inattendue s'est produite lors de la création de votre
    répétition.
  event: Événement
  formula: Formule
  formula_type: Indiquer type de formule
  frequency: Fréquence
  frequency_selection_validation_error: Vous devriez au moins sélectionner une option.
  load_more: Charger plus
  create_frequency: Créer une fréquence
  no_repetition_available: Il n'y a pas de répétition disponible
  notification: Notification
  repeat_every: Se répète
  repetition_exists_message: Cette répétition existe déjà
  repetitions: Répétitions
  reset: Réinitialiser
  sampling: Échantillon
  save: Enregistrer
  search: Rechercher
  select: Séléctionner une répétition
  select_formula: Selectionnez une formule
  times: Fois
report:
  double_check_toast: Un autre opérateur doit faire la vérification
  checking_data: Vérification de la saisie
  rechecking_data: Revérification de la saisie
  checking_data_desc: Vérifiez la valeur, puis validez en appuyant sur OK, ou rejetez en cliquant sur NOK si elle est incorrect. 
  all_day: Toute la journée
  category: Catégorie
  cell_history: Historique des saisies
  cell_history_desc: Ce champ a été modifié par un ou plusieurs utilisateurs
  checkbox: À Faire
  control: Contrôle
  controls: Contrôles
  custom_valid_controls: '{header} valides'
  date: Date
  document: Fiche
  done: Fait
  download_failed: Erreur
  download_failed_message: >-
    Une erreur est survenue pendant la préparation de votre fiche. | Une erreur
    est survenue pendant la préparation du vos fiches.
  download_finished: Téléchargement en cours
  download_finished_message: Vous recevrez un email contenant le fichier dans quelques minutes.
  download_limit_reached: Téléchargement limité a 45 rapports
  downloading: Téléchargement en cours...
  downloading_message: >-
    Veuillez patienter, nous préparons votre fiche pour le téléchargement ! |
    Veuillez patienter, nous préparons vos fiches pour le téléchargement !
  dynamic: Dynamique
  dynamic_target: Cible dynamic
  enter_value: Saisir valeur...
  execution_date: Date d'exécution
  filter_invalid: Non valide
  filter_last_3_days: Derniers 3 jours
  filter_last_7_days: Dernier 7 jours
  filter_last_day: Dernier jour
  filter_last_month: Dernier mois
  filter_valid: Valide
  is_valid: État
  job: Ordre de fabrication
  job_abbreviation: OF
  justification: Justification
  justify_ko_desc: >-
    Veuillez justifier la valeur de la cellule suivante pour continuer la saisie
    sur cette fiche
  justify_ko_title: Justification de non-conformité
  last_update: Dernière sauvegarde le
  limit_reached: Vous avez atteint votre limite, veuillez affiner votre recherche.
  measure: Mesure
  modify_data: Modification d'un champ précédemment saisi
  modify_data_desc: >-
    Les données de cette cellule ont été saisies par un autre utilisateur. Ces
    nouvelles données seront actualisées sur les grilles de chaque intervenant
    sur la fiche.
  modify_data_error: Tous les champs ne sont pas remplis correctement
  modify_data_success: La nouvelle donnée à bien été enregistré
  new_data: Nouvelle donnée
  no_rows_to_show: Problème d'affichage des données
  no_steps: Ce fichier est vide
  operator: Opérateur
  precise_reasons: Préciser les raisons de cette modification...
  product: Produit
  raise_event: Signaler un événement
  report_update_message: La fiche a été enregistré avec succès
  restore_data: Rétablir la saisie
  restore_data_desc: >-
    Ces données ont été saisie antérieurement avant d'être remplacées par de
    nouvelles saisies plus récentes.
  restore_data_title: Voulez-vous vraiment rétablir ces données ?
  see_alert: Voir l'alerte
  select_option: Sélectionner un/des éléments
  static: Statique
  status: Statut
  status_finished: Terminé
  status_history: Historique des statuts
  status_in_progress: En cours
  status_new: Nouveau
  step_answer: Réponse
  step_answer_state: État
  step_name: Nom
  step_number: Etape
  target: Cible
  text: Texte
  update_date: Date de mise à jour
  valid_controls: Contrôles valides
  view_report: Voir le rapport
  work_order: OF
  workplace: Poste de charge
session:
  all_documents_not_complete: Vos fiches ne sont pas toutes terminées
  all_documents_not_complete_desc: >-
    Vous ne pouvez pas terminer l'opération tant que toutes les fiches
    obligatoires ne sont pas terminées
  automatic_reports: Rapports automatiques
  create_operation: Créer une nouvelle opération
  create_workorder: Créer un nouvel ordre de fabrication
  launch_on: Lancer sur
  of_name_requiered: Veuillez choisir un nom pour votre ordre de fabrication
  ongoing_session: Session commencée
  ongoing_session_message: Vous êtes sur le point de commencer une session déja démarrée
  operation_requiered: Veuillez créer au moins une nouvelle opération
  product_requiered: Sélectionnez au moins un produit pour continuer
  refine_search: Affinez votre recherche pour faire apparaître les OFs associés
  search_po: Rechercher
  select_product: Sélectionner le produit
  certified: Certifié
  uncertified: Non certifié
  to_certify: Certifier
  certification: Certification
  certification_updated_successfully: Équipe modifiée avec succès
  add_team: Ajouter une équipe
  chain_manager: Responsable
  select_chain_manager: Sélectionner le responsable
  select_chain_manager_placeholder: Veuillez sélectionner le responsable
  select_team_members: Sélectionner les opérateurs
  select_team_members_placeholder: Veuillez sélectionner les opérateurs
  line_certification_verification: Vérification de la certification des lignes
  select_team_desc: Sélectionner les personnes qui composent votre équipe
  update: MàJ
  no_attachments: Les nouvelles pièces jointes apparaîtront ici une fois que vous les aurez ajoutées.
  workorder_doesnt_exist: Cet ordre de fabrication n'existe pas.
settings:
  add_option: Veuillez saisir votre option et appuyer sur entrée
  additional_data: Données complémentaires
  alerts: Alertes
  alternative_workplaces: Postes de charge alternatifs
  audit_logs:
    action: Activité
    action_hour: Heure d'activité
    deactivate: Désactivation
    get_more: Charger + { count } entrée | Charger + { count } entrées
    load_more: Plus d'entrées
    login: Connexion
    logout: Déconnexion
    name: Compte associé
    reactivate: Activation
  blocker: Bloquant
  cancel: Annuler
  color: Couleur
  conception_rights: Droits de conception
  create_ftp_success_message: Le serveur FTP a bien été enregistrer avec succès
  create_group: Créer un groupe
  create_lookup: Créer une nouvelle donnée
  create_product: Créer un nouveau produit
  create_role: Ajouter un rôle
  create_setting_success_message: Le paramètre a bien été créé avec succès
  create_status: Créer un statut
  create_workplace: Créer un nouveau poste de charge
  decimal_number: Nombre de décimale
  decimal_values: Valeurs décimales
  default: Défaut
  delete: Supprimer le paramètre
  delete_confirmation_message: >-
    Êtes-vous sûr de vouloir supprimer le paramètre ? Cette action est
    irréversible.
  delete_lookup_description: Êtes-vous sûr de vouloir archiver cette donnée ?
  delete_lookup_title: Archiver la cellule
  delete_option: Supprimer l'option
  delete_option_confirmation_message: >-
    Êtes-vous sûr de vouloir supprimer cette option ? Cette action est
    irréversible.
  delete_product_description: Êtes-vous sûr de vouloir archiver ce produit ?
  delete_product_title: Archiver un produit
  delete_setting_success_message: Le paramètre a bien été supprimé avec succès
  delete_workplace_description: Êtes-vous sûr de vouloir archiver ce poste de charge ?
  delete_workplace_title: Archiver un poste de charge
  disabled: Désactivé
  document_category: Catégorie de fiche
  document_status: Fiche statut
  enable_tolerance: Activer la tolérance dans les mesures
  enabled: Activé
  ending_time: Heure de fin
  exigence: Exigence
  formula: Formule
  frequency: Fréquence
  get_more_document_category: Charger + { count } catégorie | Charger + { count } catégories
  get_more_grid_header: Charger + { count } en-tête | Charger + { count } en-têtes
  get_more_list_options: >-
    Charger + { count } list de sélection | Charger + { count } Listes de
    sélection
  get_more_means_of_measure: Charger + { count } moyen de mesure | Charger + { count } moyens de mesure
  get_more_messages: Charger + { count } message | Charger + { count } messages
  get_more_step_tag: Charger + { count } étiquette | Charger + { count } étiquettes
  get_more_teams: Charger + { count } équipe | Charger + { count } équipes
  get_more_units_of_measure: Charger + { count } unité de mesure | Charger + { count } unités de mesure
  get_more_workflow_models: Charger + { count } cycle de vie | Charger + { count } cycles de vie
  grid_header: En-tête
  groups: Groupes
  header_list: Séquence
  input_modification: Modification de saisie
  input_parameters: Saisies
  is_selected: Sélectionné
  justify_ko: Justification NC
  list_options: Liste de sélection
  list_type: Titre de la liste
  list_value: Valeur de l'option
  load_more_document_category: Plus de catégories
  load_more_grid_header: Plus d'en-têtes
  load_more_list_options: Plus de listes de sélection
  load_more_means_of_measure: Plus de moyens de mesure
  load_more_messages: Plus de messages
  load_more_step_tag: Plus d'étiquettes
  load_more_teams: Plus d'équipes
  load_more_units_of_measure: Plus d'unités
  load_more_workflow_models: Plus de modèles
  lookup_exists: Données déjà existantes, veuillez modifier l'un des champ.
  lookup_keys_error: >-
    La configuration du fichier ne correspond pas aux clés de ce type. Veuillez
    contacter votre responsable des opérations.
  lookup_tables: Table de mapping
  mandatory_column: Ce champs est obligatoire
  mandatory_fields: Assurez-vous de remplir tous les champs obligatoires
  mandatory_finish: Saisie intégrale
  maximum_inactivity_time: Temps d'inactivité avant déconnexion
  enable_incativity_logout: Déconnexion après inactivité
  means_of_measure: Moyen de mesure
  measure_unit: Unité de mesure
  media_library: Activé
  members: Membres
  message: Contenu
  mold: Moule
  name: Nom
  nature: Nature
  new_status: Statut sans nom
  new_user: Nouveau utilisateur
  no_user_found: Aucun utilisateur n'a été trouvé
  number_of_days_of_validity: Nombre de jours de validité du mot de passe actuel
  number_of_decimals: Nombre de chiffres significatifs
  operator_session: Session
  order: Ordre
  organization: Organisation
  parameter_name: Nom
  parameter_value: Valeur
  password_expiration: Expiration des mots de passe
  plan: Plan
  precise_decimal_number: Préciser le nombre de décimale
  product: Produit
  products:
    export: Exporter la liste des produits en format csv
    import: Importer un fichier csv
  profile: Profil
  reasons: Justification de modification
  repetition: Répétition
  repetition_type: Type de répétition
  report_status: Report status
  reset_automatically: Réinitialisation automatique
  roles: Rôles & permissions
  roles_access: Accessible à
  roles_cannot_delete_description: >-
    {amount} utilisateur(s) possèdent ce rôle. Merci de leur attribuer un rôle
    différent ou de le supprimer au préalable
  roles_cannot_delete_title: Impossible de supprimer le rôle
  roles_change-status: Modif. statut
  roles_confirm_delete_description: >-
    En confirmant, ce rôle va être définitivement supprimé. Cette action est
    irréversible.
  roles_confirm_delete_title: Confirmer la suppression ?
  roles_create: Créer
  roles_delete: Archiver
  roles_delete_role: Supprimer
  roles_edit_name: Editer le nom
  roles_modify: Modifier
  roles_new: Nouveau rôle
  roles_read: Consulter
  roles_ressource: Ressource
  same_as_tolerance: Identique à la tolérance
  sampling: Echantillonnage
  save_status: Sauvegarder
  search_user_placeholder: Rechercher et ajouter des nouveaux membres
  settings_error: Un problème a été rencontré pendant la connexion au serveur
  starting_time: Heure de début
  status_color: Couleur
  status_icon: Icône
  status_name: Nom
  status_saved: Le statut a été sauvegardé
  step_tag: Étiquette
  team: Equipe
  team_name: Nom d'équipe
  theTeam: L'equipe
  tolerance: Tolérance
  type: Type
  unit: Unité
  units_of_measure: Unités de mesure
  update_group: Modifier un groupe
  update_lookup: Modifier une cellule
  update_password_message_description: L'utilisateur devra se reconnecter avec le nouveau mot-de-passe
  update_password_message_title: Confirmer le changement de mot de passe ?
  update_product: Mettre à jour un produit
  update_setting_error_message: Une erreur s'est produite. Le paramètre n'a pas été modifié.
  update_setting_success_message: Le paramètre a été modifié avec succès
  update_setting_tolerance_error_message: Vous ne pouvez pas modifier la tolérance
  update_workplace: Mettre à jour un poste de charge
  users: Utilisateurs
  users_and_access: Comptes et accès
  value: Valeur
  value_exists: Ce nom est déja utilisé
  value_tolerance: Valeur de la tolérance
  workflow: Cycle de vie
  workplaces:
    export: Exporter la liste des postes de charges en format csv
    import: Importer un fichier csv
sidebar:
  documents: Fiches
  handle_documents: Gérer mes fiches
  incidents: Incidents
  manage_production: Piloter ma production
  media_library: Médiathèque
  performance_analysis: Analyser ma performance
  performance_analysis_production: Production
  performance_analysis_quality: Qualité
  production_monitoring: Suivi de production
  production_order_monitoring: Suivi des OFs
  incident_form: Formulaires d'incident
  manage_projects: Gérer mes projets
simulation:
  empty_simulation: >-
    Vous n'avez pas de simulations pour le moment. Vous pouvez commencer à en
    créer.
  new_simulation: Nouvelle simulation
sso:
  authentication_error: >-
    Une erreur s'est produite lors de la connexion au fournisseur sso, veuillez
    vérifier auprès de votre administrateur
  connect: Connecter avec votre fournisseur SSO
  microsoft_connection: Connexion Microsoft (SSO)
messages:
  no_data: Aucun rapport disponible
status:
  created: Le statut a été créé avec succès
  deleted: Le statut a été supprimé avec succès
  status: Statut
  statuses: Statuts
  updated: Le statut a été mis à jour avec succès
step:
  absolute_value: Valeur absolue
  add_condition: Ajouter une condition
  add_file: Ajouter un fichier
  add_one_or_multiple_files: Ajouter un ou plusieurs fichiers
  create_calculation: Créer un calcul
  preview_calculation: Voir le calcul
  incorrect_calcul: Erreur calcul
  delete_all: Supprimer tout
  requested_entries: Entrées demandées
  calcul_preview: Prévisualisation du calcul
  calculation_step: Bloc calcul
  block: Bloc
  mathematical_operators: Opérateurs mathématiques
  create_calcul_placeholder: Créer votre calcul en ajoutant des entrées requises et des opérateurs mathématiques.
  fill_calcul_btn: Effectuer un calcul
  fill_calcul_title: Remplissez les champs suivants pour obtenir le résulat du calcul
  answer_nok: Nok
  answer_ok: Ok
  boolean: OK / NOK
  checkbox: Case à cocher
  create_answer: Créer une réponse
  customized_answer: Réponse personnalisée
  delete_step_description: >-
    La suppression de ce bloc est impossible car il dépend d'un bloc
    conditionnel.
  delete_step_calcul_description: >-
    La suppression de ce bloc est impossible car il dépend d'un bloc
    calcul.
  delete_step_title: Suppression du bloc impossible
  description_placeholder: Entrer une description
  done: fini
  file: Fichier
  file_upload_issue: Un problème est rencontré lors de chargements de fichier
  link: Lien
  list: Sélection
  mandatory: Obligatoire
  double_check: Double vérification
  margin: Marge
  means: Moyen de mesure
  measure: Mesure
  measure_max: Max
  measure_min: Min
  measure_target: Cible
  measure_tolerance: +/-
  measure_unit: Unité
  multiple_selections: Choix multiples
  name_placeholder: Entrer un nom
  napossible: Non applicable
  no_step_available: Aucun bloc disponible
  percentage: Pourcentage
  select_condition_item_in_list: Sélectionner une réponse...
  select_condition_step_in_list: Sélectionner les blocs à afficher...
  set_value: Valeur définie
  take: Fois
  uncertainty: Incertitude
template:
  archive: Archiver le modèle
  archive_template: Archiver
  are_you_sure_edit: >-
    La modification de ce modèle modifiera tous les fiches associées, êtes-vous
    sûr de vouloir continuer ?
  create: Nouveau modèle
  delete_confirmation_message: Voulez-vous vraiment archiver ce modèle?
  get_more: Charger + { count } modèles
  load_more: Plus de modèles
  modify: Modifier le modèle
  new_template: Nouveau modèle
  publish_template: Publier
  reference: Référence
  search: Rechercher un modèle
  template_delete_used_reference: >-
    L'archivage du modèle entraînera des modifications irréversible dans les
    fiches suivantes :
  template_name: Nom du modèle
  template_update_documents_error_message: Certaines fiches n'ont pas été mis à jour correctement, veuillez réessayer.
  template_update_error_message: Une erreur s'est produite lors de l'enregistrement du modèle
  template_update_message: Le modèle a été modifié avec succès
  template_used_reference: >-
    Toute modification du modèle est irréversible et entraînera des
    modifications dans les fiches suivantes :
  unlink_template: Dissocier
trigger:
  day: Journée
  production_order: Ordre de fabrication
  team: Equipe
user:
  active: Actif
  automatic_deactivation: Désactivation automatique
  by: par
  client: Client
  confirmation_description_activate_user: L'utilisateur aura de nouveau accès à JUNO.
  confirmation_description_desactivate_user: >-
    L'utilisateur ne pourra plus accéder à JUNO une fois que son compte sera
    désactivé.
  confirmation_title_activate_user: Êtes-vous sûr de vouloir réactiver le compte de {name} ?
  confirmation_title_desactivate_user: Voulez-vous vraiment désactiver le compte de {name} ?
  deactivated_successfully: Ce compte à été désactivé
  desactivate: Désactiver
  disable_account: Désactiver le compte
  disabled: Désactivé
  disabled_at: Désactivé le
  disabled_automatically_at: Désactivé automatiquement le
  edit_information: Modifier les informations
  email: Email
  first_name: Prénom
  language: Langue
  last_name: Nom
  modify_password: Modifier le mot de passe
  nfc_authentication_status: Statut d'authentification NFC
  nfc_code: Code NFC
  nfc_status: Statut NFC
  nfc_text_validity: Tester la validité NFC
  password: Mot de passe
  personal_information: Informations personnelles
  reactivate: Réactiver
  reactivate_account: Réactiver le compte
  reactivated_successfully: Ce compte à été réactivé
  role: Rôle
  role_attribution_mandatory: L'attribution d'un rôle est obligatoire
  site_attribution_mandatory: L'attribution d'un site est obligatoire
  site: Site
  sso_authentication: Authentification centralisée (SSO)
  user_certified: Certification de l'utilisateur
  operator_certified: Opérateur certifié
  chain_manager_certified: Responsable certifié
  sso_only_error: Uniquement l'authentification SSO est activée pour cet utilisateur
  status: Statut
  temporary: Temporaire
  temporary_account: Compte temporaire
  unknown_creator: Créateur inconnu
  updated: L'utilisateur a été mis à jour
  use_sso_only: >-
    En activant ce bouton, l'utilisateur devra obligatoirement avoir une adresse
    mail et ne pourra pas se connecter autrement qu'avec cette adresse
users:
  get_more: Charger + { count } utilisateur | Charger + { count } utilisateurs
  load_more: Plus d'utilisateurs
  readonly_mode: Mode lecture seule
validate_nc: Valider les NC
validations:
  document_category: La catégorie ne doit pas être vide
  document_description: Le champ description est obligatoire
  document_name: Le champ nom est obligatoire
  minimum_characters: Veuillez saisir au moins {length} caractères
views:
  add: Créer une vue
  delete: Supprimer la vue
  delete_confirmation_message: Êtes-vous certain de vouloir supprimer cette vue ?
  delete_modal_title: Supprimer une vue
  description: Description de la vue
  error_create: Une erreur est survenue lors de la création de la vue
  error_delete: Une erreur est survenue lors de la suppression de la vue
  error_empty_title: Le titre ne doit pas être vide
  error_update: Une erreur est survenue lors de l'enregistrement de la vue
  filters: Filtres actifs
  get_more_reports: Charger + { count } rapport | Charger + { count } rapports
  load_more_reports: Plus de rapports
  name: Nom de la vue
  new_vue_template_description: Entrer une description ici
  new_vue_template_title: Nouvelle vue
  no_filters_selected: Aucun filtre sélectionné
  no_group: Aucun
  save: Sauvegarder la vue
  save_view: Sauvegarder
  saved_views: Vues sauvegardées
  title: Supervision
  view_all: Voir tout
  view_created: Une nouvelle vue a été créée avec succès
  view_delete: La vue a été supprimée avec succès
  view_updated: La vue a été modifiée avec succès
welcome_url:
  analytics: Analyse
  conception: Conception
  designer_space: Espace supervision
  library: Bibliothèque
  operation: Opération
  operator_free: Postes de charge
  operator_selection: Sélection de poste de charge
  operator_space: Espace production
  select_option: Sélectionner...
  space: Espace
  supervision: Supervision
  welcome_page: Ecran d'accueil
workflows:
  add: Ajouter une étape
  add_description: Ajouter une description
  associated_permissions: Permissions associées
  associated_status: Statut associé
  built_in: Cycle de vie par défaut
  create: Créer un cycle de vie
  created: Le cycle de vie a été créé avec succès
  customizable: Cycles de vie customisables
  default: Cycle de vie par défaut
  default_alert_step_1: Nouvelle alerte
  default_alert_step_2: Alerte en cours
  default_alert_step_3: Alerte gérée
  default_document_step_1: Fiche en cours d'édition
  default_document_step_2: Fiche publié
  default_of_step_1: Nouvel OF
  default_of_step_11: OF libéré
  default_of_step_2: OF en cours
  default_of_step_3: OF complété
  default_operations_step_1: Nouvelle opération
  default_operations_step_2: Opération en cours
  default_operations_step_3: Terminer opération
  default_report_step_1: Nouveau rapport
  default_report_step_2: Rapport en cours
  default_report_step_3: Rapport terminé
  description: Description
  error: Une erreur s'est produit lors de la création/mis à jour du cycle de vie
  error_inputs: Veuillez remplir les champs obligatoires pour valider la nouvelle étape
  name: Nom du cycle de vie
  new_workflow_title: Nouveau cycle de vie
  no_workflow: Aucun cycle de vie
  not_found: Aucun cycle de vie trouvé
  not_found_description: >-
    Créer un nouveau cycle de vie en cliquant sur le bouton en haut à droite de
    l'écran
  save: Sauvegarder le cycle de vie
  search_status: Rechercher un statut...
  select_option: Sélectionner un statut
  select_workflow: Sélectionner un cycle de vie
  select_workflow_type: Sélectionnez un type de cycle de vie pour en créer un nouveau
  templates: Modèles
  title: Cycle de vie
  type: Type
  type_alerts: Alert
  type_documents: Fiches
  type_of: Ordre de fabrication
  type_operations: Opération
  incident: Incident
  type_reports: Rapport
  type_select: Sélectionner un type
  unnamed: Cycle de vie sans nom
  updated: Le cycle de vie a été mis à jour avec succès
workorder:
  complete: Terminer l'opération
  confirm_modal_description: >-
    Vous êtes sur le point de terminer l'opération, avez-vous bien complété
    l'ensemble de vos fiches ?
  confirm_modal_title: Terminer et clôturer l'opération en cours
  set_operation_in_progress: Remettre l'opération en cours
incident_form:
  new: Nouveau formulaire
  new_step: Nouvelle étape
  title: Formulaires d'incident
  my_forms: Mes formulaires
  remove_branch: Êtes-vous sûr de vouloir supprimer cette branche ?
  remove_branch_description: Cette action ne peut pas être annulée. Toutes les valeurs associées à cette branche seront perdues.
simulator:
  generate: Générer
  derivation: Dérivation
  derivation_data: Dérivations data
  available_only_for_pilot_mode: Disponible uniquement en mode pilote
projects:
  create_new: Nouveau projet
  all_projects: Tous les projets
  assignees: Assignés
  dates: Dates
  deadline: Date d'échéance
  priority:
    title: Priorité
    low: Faible
    medium: Moyenne
    high: Elevée
    tbd: À faire
  progress: Progrès
  budget: Budget
  title: Projet
  information: Informations du projet
  localisation: Localisation
  new: Nouveau projet
  assignees_placeholder: Aucun assigné
  name_placeholder: Donnez un nom au projet
  budget_placeholder: Donnez un coût au projet
  description_placeholder: En quoi consiste ce projet ?
  deadline_placeholder: Aucune date
  priority_placeholder: Quelle est la priorité de ce projet ?
  localisation_placeholder: Quelle est la localisation de ce projet ?
  demo:
    project_1_title: Amélioration de la disponibilité machine
    project_1_description: "Améliorer l'efficacité et la qualité du processus de changement de gamme pour réduire les temps d'arrêt, les défauts de qualité et les pertes de productivité"
    project_2_title: Diminuer le taux des rebuts
    project_3_title: Réduire le délai des livraisons
  filters:
    day_hour: Jour/Heure
    week_day: Semaine/Jour
    month_day: Mois/Jour
    year_month: Année/Mois
    year: Année
  actions:
    all_actions: Toutes les actions
    activity: Activité
    create_new: Nouvelle action
    deliverable: Livrable
    add_delivery_description_placeholder: Entrer une description...
    add_delivery_file: Joindre un fichier
    activity_all: Tous
    activity_comment: Commentaires
    activity_status: Historique
    add_new: Ajouter une action
    demo:
      action_1_title: Former le personnel
      action_1_description: "Collecte de données: Recueillir des données sur les temps d'arrêt et les erreurs lors des précédents changements de gamme."
      action_2_title: Mettre en place des contrôles qualité supplémentaires
      action_3_title: Automatiser les tâches manuelles et répétitives dans le processus de traitement des commandes
      action_4_title: "Impliquer les opérateurs dans l'identification et la résolution des problèmes liés aux rebuts"
      action_5_title: "Solliciter les commentaires des clients sur leur expérience de livraison et identifier les domaines d'amélioration"
      action_6_title: "Analyser les temps d'arrêt"
      action_7_title: Mettre en place une maintenance préventive
      action_8_title: Améliorer la gestion des pièces de rechange
      action_9_title: "Optimisation de l'approvisionnement"
    project: Projet
    title: Actions du projet
    history:
      assigned: a assigné l'action à
      commented: a commenté
      created: a créé l'action
      updated: a changé le statut de l'action
    project_placeholder: Ajouter l'action à des projets
    category_placeholder: Aucune catégorie
    name_placeholder: Donnez un nom à l'action
    no_description: Aucune description n'a été renseignée.
    description_placeholder: En quoi consiste cette action ?
