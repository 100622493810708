import Cell from './Cell'
import type { JInputData } from '~/models/report/jInputData'
import { type JStep, StepType } from '~/models/documents/jStep'

const options = {
  NA: 'NA',
  Ok: 'Ok',
  NOk: 'NOk',
  true: 'Ok',
  false: 'NOk',
  undefined: null,
  null: null,
}
export type StepBooleanOptions = 'Ok' | 'NOk' | 'NA' | null

export class BooleanCell extends Cell {
  private _cellStatus: StepBooleanOptions

  constructor(
    rowIndex: number,
    colIndex: number,
    inputs: JInputData[],
    step: JStep,
    isHistory: boolean,
    onExport: false,
    report_id: null,
  ) {
    if (step?.type !== StepType.Boolean) throw new Error('Type mismatch')
    super(rowIndex, colIndex, inputs, step, isHistory, onExport, report_id)
    this._cellStatus = this.latestValue
  }

  get cellStatus(): StepBooleanOptions {
    return this._cellStatus
  }

  set cellStatus(newStatus: StepBooleanOptions) {
    this._cellStatus = newStatus
  }

  get latestValue(): string {
    const value = super.latestValue
    return value
  }

  addInputData(inputData: JInputData) {
    inputData.value = options[String(inputData.value)]
    super.addInputData(inputData)
    this._cellStatus = options[this.latestValue]
  }

  parseAnswer(): string | undefined {
    return options[super.parseAnswer() ?? 'undefined']
  }
}

export default BooleanCell
