export enum DocumentStatus {
  new = 1,
  in_progress,
  finished,
  draft,
  published,
  archived,
  deleted,
}

export enum DocumentStatusName {
  draft = 'draft',
  published = 'published',
  archived = 'archived',
}

export enum BuiltInStatus {
  new = 1,
  in_progress,
  finished,
  draft,
  published,
  archived,
  deleted,
  select,
  active,
  qualified,
  released,
  being_processed,
  resolved,
}

export enum BuiltInStatusMESX {
  available = 'f5a04ebe-1c3a-4a33-8513-351cdcb22d69',
}

export enum TemplateStatus {
  draft = 4,
  published,
  archived,
  deleted,
}

export enum IncidentFormStatus {
  draft = 4,
  published,
  archived,
}

export enum ReportStatus {
  new = 1,
  in_progress,
  finished,
  released = 11,
}

export interface JobDataModel {
  clientId: string
  siteId: string

  createdBy: string
  updatedBy: string

  createdAt: Date | number
  createdAtRelative?: string
  updatedAt: Date | number
  updatedAtRelative?: string
}

export interface JobDataApiModel {
  client_id: string
  site_id: string

  created_by: string
  updated_by: string

  created_at: Date | number
  updated_at: Date | number
}

export interface StatusRawModel {
  id?: number
  builtIn: boolean
  name: string
  textColor: string
  bgColor: string
  iconName: string
  iconColor: string
}

export interface StatusModel extends StatusRawModel, JobDataModel {}
