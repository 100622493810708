<script setup lang="ts">
import uniqid from 'uniqid'
import _ from 'lodash'
import { format } from 'date-fns'
import type Cell from '~/grid/Cell'
import type Grid from '~/grid/Grid'
import { Icons } from '~/models/Icon'
import type { StepBooleanOptions } from '~/grid/BooleanCell.ts'
import { gridStore } from '~/store/grid'
import { siteStore } from '~/store/site'
import DoubleCheck from './DoubleCheck.vue'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
  isCleared: {
    type: Boolean,
    default: false,
    required: false,
  },
  isError: {
    type: Boolean,
    default: false,
    required: false,
  },
  isFromModal: {
    type: Boolean,
    default: false,
    required: false,
  },
})

const emit = defineEmits<{
  (e: 'setNewValue', val: any): void
  (e: 'triggerOnCellChanged', item: any): void
}>()

const id = uniqid('id-')

const state = reactive({
  refName: `cell_inactive_div_${props?.params?.colDef?.colId}_${props?.params?.rowIndex}`,
  rowIndex:
    props.params?.node?.data?.index !== undefined
      ? props.params?.node.data?.index
      : props?.params?.rowIndex,
  colIndex:
    props?.params?.colDef?.index -
    props.params?.data?.details?.initialShiftIndex,
  value: null,
})

const grid = computed<Grid>(() => {
  if (props.params?.colDef?.reportId) {
    return gridStore(String(props.params?.colDef?.reportId))?.getGrid
  }
  return gridStore().getGrid
})

const cell = computed<Cell | undefined>(() => {
  return grid.value
    ?.getCellByIndex(state.rowIndex, state.colIndex)
    ?.initializeActivationState(props.params)
})

const getStatusValue = (newStatus: StepBooleanOptions) => {
  let actualValue: null | string = null

  switch (newStatus) {
    case 'Ok':
      actualValue = 'true'
      break
    case 'NOk':
      actualValue = 'false'
      break
    case 'NA':
      actualValue = 'NA'
      break
  }
  return actualValue
}

const cellStatus = computed({
  get: () => {
    return props.isCleared
      ? state.value
      : props.params?.answer?.value !== undefined
        ? props.params.answer.value
        : cell.value?.cellStatus
  },
  set: (newStatus) => {
    if (cell.value && !props.isCleared) {
      if (!grid?.value?.isJustifyKoEnabled() || newStatus !== 'NOk')
        cell.value.cellStatus = newStatus

      const actualValue = getStatusValue(newStatus)
      // set the grid value to undefined in order to trigger the onCellChanged with the actual value even if values are the same
      if (props.params?.value === actualValue)
        props.params?.node?.setDataValue(props.params?.column?.colId, undefined)

      props.params?.node?.setDataValue(props.params?.column?.colId, actualValue)
    }
  },
})

const latestInputData = computed(() => {
  return cell?.value?.getLatestInputData()
})

const showHistoryIcon = siteStore().getFlag('history')

const isEditable = computed(() => {
  return props.isCleared || _.cloneDeep(cell?.value)?.isEditable(props.params)
})

const isActivated = computed(() => {
  return cell?.value?.isActivated
})

const isNotApplicable = computed(() => {
  return (
    cell?.value?.step?.is_not_applicable ??
    props?.params?.details?.is_not_applicable
  )
})

const getButtonClass = (status) => {
  const cursor = isEditable.value ? 'cursor-pointer' : 'cursor-default'
  if (cellStatus?.value === status) {
    return `Content gap-2 px-2 py-0 ${cursor} ${status === 'NOk' ? 'bg-[#f5222d]' : 'bg-[#1890ff]'}`
  }

  return `Content gap-2 px-2 py-0 ${cursor}`
}

const activateCell = () => {
  cell.value?.setIsActivated(isEditable.value)
}

const onclick = (newStatus: 'Ok' | 'NOk' | 'NA') => {
  if (!isEditable.value) return

  if (props.isCleared) {
    state.value = newStatus

    emit('setNewValue', getStatusValue(newStatus))
  } else if (cell.value?.cellStatus === newStatus) {
    cellStatus.value = null
  } else if (cell?.value) {
    cellStatus.value = newStatus
  }
}

const history = computed(() => {
  return cell?.value?.getHistoryTooltip()
})
</script>

<template>
  <div v-if="!cell?.step?.hidden">
    <div
      v-if="isActivated || props.isFromModal"
      class="flex gap-2"
      :class="`${$props.params?.details && 'items-center justify-center'}`"
    >
      <DoubleCheck
        :input-data="latestInputData"
        :cell="cell"
        :is-from-modal="props.isFromModal"
        :is-history="cell?.isHistory"
      />
      <div
        v-if="
          (cell?.hasHistory && showHistoryIcon && !props.isFromModal) ||
          (latestInputData?.reason && !props.isFromModal)
        "
        v-can.any="['read-report', 'create-report']"
        :tooltip="grid.onExport ? history : null"
        class="absolute right-1 top-1"
        data-cy="history-button"
        @click.stop="gridStore().setHistoryModalCell(cell ?? null)"
      >
        <component
          :is="Icons.RECENTLY_VIEWED"
          class="h-5 w-5 cursor-pointer text-grey-500"
        />
      </div>
      <div
        v-if="latestInputData?.updated_by && !props.isFromModal"
        v-can.any="['read-report', 'create-report']"
        class="absolute bottom-1 right-1 flex flex-col-reverse items-center pb-2"
        data-cy="avatar-button"
      >
        <AvatarMultiple
          :user-ids="[latestInputData?.updated_by]"
          :update-date="latestInputData?.update_date"
          :hide-full-name="!grid?.onExport"
          display-amount
          is-enable-modal
        />

        <span
          v-if="latestInputData?.update_date && grid.onExport"
          class="text-center text-sm text-gray-600"
          >{{
            format(new Date(latestInputData?.update_date), 'dd/MM/yy - HH:mm')
          }}</span
        >
      </div>
      <div
        class="multi-toggle-container"
        :class="{
          error: props.isError,
          'read-only': grid.isHistory,
          'text-base': !grid.onPdf,
          'text-xl': grid.onPdf,
        }"
        data-cy="cell-radio"
        :data-cy-column="
          props.params?.colDef?.index -
          props.params?.data?.details?.initialShiftIndex
        "
      >
        <div
          data-cy="cell-radio-button"
          :class="getButtonClass('Ok')"
          @click="onclick('Ok')"
        >
          <label
            :id="id"
            :class="`${
              cellStatus === 'Ok'
                ? `text-white ${isEditable ? 'cursor-pointer' : 'cursor-default'}`
                : `labelRadio text-primary ${isEditable ? 'cursor-pointer' : 'cursor-default'}`
            }`"
            >Ok
          </label>
        </div>

        <div class="Line" />
        <div
          data-cy="cell-radio-button"
          :class="getButtonClass('NOk')"
          @click="onclick('NOk')"
        >
          <label
            :id="id"
            :class="`${
              cellStatus === 'NOk'
                ? `text-white ${isEditable ? 'cursor-pointer' : 'cursor-default'}`
                : `labelRadio text-primary ${isEditable ? 'cursor-pointer' : 'cursor-default'}`
            }`"
            >NOk
          </label>
        </div>
        <div
          v-if="isNotApplicable"
          data-cy="cell-radio-button"
          class="Line"
        />
        <div
          v-if="isNotApplicable"
          data-cy="cell-radio-button"
          :class="getButtonClass('NA')"
          @click="onclick('NA')"
        >
          <label
            :id="id"
            :class="`${
              cellStatus === 'NA'
                ? `text-white ${isEditable ? 'cursor-pointer' : 'cursor-default'}`
                : `labelRadio text-primary ${isEditable ? 'cursor-pointer' : 'cursor-default'}`
            } text-base`"
            >N/A
          </label>
        </div>
      </div>
    </div>
    <div
      v-else
      :id="state.refName"
      class="rounded bg-[#CBCCCD] px-2 py-1.5 text-[#FFFFFF]"
      @click="activateCell"
    >
      N/A
    </div>
  </div>
</template>

<style lang="scss">
.checkbox-round {
  width: 0.9em;
  height: 0.9em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid gray;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  box-shadow:
    0px 0px 0px 0.5px #116bd2,
    inset 0px 0px 0px 0.14em white;
  background-color: #116bd2;
}

.multi-toggle-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  border: solid 1.5px #dee2ea;
  background-color: #fff;

  &.error {
    @apply border border-solid border-red-500;
  }
}

.Line {
  width: 1px;
  height: 1.5rem;
  margin: 0 1.5px;
  transform: rotate(-360deg);
  background-color: #dee2ea;
}

.Content {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 3px;
}

.labelRadio {
  flex-grow: 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
}
</style>
