import { Timestamp } from 'firebase/firestore'
import { getUsers } from '../settings'
import { isUndefinedOrNullOrEmpty, removeInstance } from './../../utils/object'
import { StepType } from './../../models/documents/jStep'
import {
  ApplicationFieldType,
  DuplicateDocumentOptions,
  JDocument,
} from '~/models/documents/jDocument'
import { DocumentStatus } from '~/common/models/status'
import {
  DOCUMENT_SETTINGS_COLLECTION_NAME,
  STEPS_COLLECTION_NAME,
} from '~/config/storage'
import DbHelper from '~/helpers/dbHelper'
import loggerHelper from '~/helpers/LoggerHelper'
import type { JRepetition } from '~/models/documents/documentSettings'
import { DocumentSettingsType } from '~/models/documents/documentSettings'
import { JDocument } from '~/models/documents/jDocument'
import type { JStep } from '~/models/documents/jStep'
import { SettingsType } from '~/models/settings/settings'
import {
  CellMeasureValidation,
  ValidationType,
} from '~/services/steps/CellMeasureValidation'
import { documentStore } from '~/store/document'
import { documentSettingsStore } from '~/store/documentSettings'
import { settingsStore } from '~/store/settings'
import { siteStore } from '~/store/site'
import { usersStore } from '~/store/users'
import { generateFirebaseLikeId } from '~/utils/formatter'

import RecordDataController from '~/controllers/recordData'
import { getUsers } from '../settings'
import { StepType } from './../../models/documents/jStep'
import { isUndefinedOrNullOrEmpty, removeInstance } from './../../utils/object'
import { FILES_ATTACHMENT_COLLECTION_NAME } from '~/common/config/firebase/storage'
import { DuplicateDocumentOptions } from '~/common/models/documents'
import { tCore } from '~/services/trpc'

import {
  CellMeasureValidation,
  ValidationType,
} from '~/services/steps/CellMeasureValidation'
import RecordDataController from '~/controllers/recordData'
import { JInputData } from '~/models/report/jInputData'
const dbHelper = new DbHelper()

// Documents
export const areWriteStepsExists = (steps: JStep[]) => {
  return (
    steps.filter(
      (p) =>
        p.type === StepType.Boolean ||
        p.type === StepType.Checkbox ||
        p.type === StepType.Measure ||
        p.type === StepType.Text ||
        p.type === StepType.List ||
        p.type === StepType.Number ||
        p.type === StepType.Time ||
        p.type === StepType.Calculator ||
        p.type === StepType.Photo,
    ).length > 0
  )
}

export const createDocument = async (
  doc: JDocument,
  options?: DuplicateDocumentOptions,
) => {
  loggerHelper.logInfo('conceptor document createDocument function')
  const document = new JDocument(doc)
  const listTypeOptions = documentSettingsStore().filterDocumentSettings(
    DocumentSettingsType.list_options,
  )

  const newDoc = await tCore.documents.createDocument.mutate({
    document,
    listTypeOptions,
    options: options as any,
  })

  return { id: newDoc.id, steps: newDoc.steps }
}

export const updateDocument = async (doc: JDocument) => {
  loggerHelper.logInfo('conceptor document updateDocument function')
  let document = JSON.parse(JSON.stringify(doc))
  document = new JDocument(document)

  const listTypeOptions = documentSettingsStore().filterDocumentSettings(
    DocumentSettingsType.list_options,
  )
  const filledDoc = await tCore.documents.updateDocument.mutate({
    document,
    listTypeOptions,
  })

  return filledDoc
}

export const updateDocumentStatus = async (
  documentId: string,
  status: DocumentStatus,
) => {
  try {
    const result = await tCore.documents.updateDocumentStatus.mutate({
      id: documentId,
      status,
    })
    loggerHelper.logInfo(
      `Document ${documentId} status has been updated : ${status}`,
    )
    return result
  } catch (e) {
    console.error(e)
    loggerHelper.logError('Error in updateDocumentStatus', e.message)
  }
}

const loadReferences = async (users, documents) => {
  const settings = settingsStore().filterSettings(
    SettingsType.document_category,
  )
  documents.map((doc: JDocument) => {
    const creator = users.find((item) => item.id === doc.created_by)
    const setting = settings.find((item) => item.id === doc.category)
    const modifier = users.find((item) => item.id === doc.updated_by)

    doc.created_by = `${creator?.first_name} ${creator?.last_name}`
    doc.updated_by = `${modifier?.first_name} ${modifier?.last_name}`
    doc.updated_by_id = modifier?.id
    doc.update_date = new Date(
      doc.update_date instanceof Timestamp
        ? doc.update_date.toDate()
        : doc.update_date,
    )

    doc.updated_at = new Date(
      doc.update_date instanceof Timestamp
        ? doc.update_date.toDate()
        : doc.update_date,
    )

    doc.category = setting?.value
    doc.categoryId = setting?.id
    doc.blocker = setting?.blocker
  })
}

interface DocumentOptions {
  perPage: number
  currentPage: number
  search: string
  bypassPagination: boolean
  withMainFilters: boolean
  filters: {
    categories: string[]
    status: DocumentStatus[]
    products: string[]
    workplaces: string[]
    operations: string[]
    templates: string[]
  }
}

interface DocumentResponse {
  items: JDocument[]
  total: number
}

export const getDocumentsWithSettings = async (
  options: Partial<DocumentOptions>,
): Promise<DocumentResponse> => {
  const [{ items, total }, users] = await Promise.all([
    await tCore.documents.getDocuments.query({
      perPage: options.perPage,
      currentPage: options.currentPage,
      search: options.search,
      bypassPagination: options.bypassPagination,
      categories: options?.filters?.categories,
      status: options.filters?.status,
      products: options.filters?.products,
      workplaces: options.filters?.workplaces,
      operations: options.filters?.operations,
      templates: options.filters?.templates,
      withMainFilters: options.withMainFilters,
    }),
    getUsers(),
  ])

  await loadReferences(users, items)

  return { items, total }
}

export const getDocumentsFromGroup = async (
  groupOptions: Partial<DocumentOptions>,
) => {
  return await tCore.documents.getDocuments.query(groupOptions)
}

export const getVersionedDocuments = async (rootDocumentId: string) => {
  return await tCore.documents.findDocumentVersions.query({
    id: rootDocumentId,
  })
}

export const getDocuments = async (
  status: DocumentStatus | DocumentStatus[],
  onlyLastVersion = true,
) => {
  onlyLastVersion = onlyLastVersion || false

  status = Array.isArray(status) ? status : [status]

  return await tCore.documents.getDocuments.query({
    status,
    bypassPagination: true,
    withMainFilters: onlyLastVersion,
  })
}

export const getAutomaticDocuments = async (
  workplaceIds: string[],
  onlyLast24Hours: false,
) => {
  const documents = await tCore.documents.findAutomaticDocuments.query({
    workplaceIds,
  })

  const availableDevices = await RecordDataController.last24HoursDevices()

  const filteredDocumentsOnWorkplace = workplaceIds.includes('all')
    ? documents.filter(
        (e) =>
          !onlyLast24Hours ||
          availableDevices.some(
            (availableDevice) =>
              availableDevice.device_name
                .toUpperCase()
                .trim()
                .includes(e.device_name.toUpperCase().trim()) &&
              e.applicationfields.some(
                (applicationField) =>
                  applicationField.type === ApplicationFieldType.product &&
                  (applicationField.id === 'ALL_PRODUCTS' ||
                    applicationField.id === availableDevice.product),
              ),
          ),
      )
    : documents.filter((e) => {
        return (
          e.applicationfields.some((e) =>
            [...workplaceIds, 'ALL_WORKPLACES'].includes(e.id),
          ) &&
          e.status !== DocumentStatus.archived &&
          (!onlyLast24Hours ||
            availableDevices.some(
              (availableDevice) =>
                availableDevice.device_name
                  .toUpperCase()
                  .trim()
                  .includes(e.device_name.toUpperCase().trim()) &&
                e.applicationfields.some(
                  (applicationField) =>
                    applicationField.type === ApplicationFieldType.product &&
                    (applicationField.id === 'ALL_PRODUCTS' ||
                      applicationField.id === availableDevice.product),
                ),
            ))
        )
      })

  for await (const doc of filteredDocumentsOnWorkplace) {
    const documentSteps = await dbHelper.getAllDataFromCollectionFromIds(
      STEPS_COLLECTION_NAME,
      doc?.steps,
    )

    doc.steps = doc.steps.map((e) =>
      documentSteps.find((stepDoc) => stepDoc.id === e),
    )
  }

  return filteredDocumentsOnWorkplace
}

export const getDocumentsFromIds = async (documentIds: string[]) => {
  return await tCore.documents.getDocumentByIds.query({
    ids: documentIds,
  })
}

export const getDocumentRef = async (idDocument: string) => {
  return await tCore.documents.find.query({ id: idDocument })
}

export const getFullDocumentById = async (idDocument: string) => {
  try {
    documentStore().setDocument(new JDocument({}))
    const doc = await tCore.documents.find.query({ id: idDocument })
    // .then((e) => new JDocument(e))) as JDocument

    documentStore().setDocument(doc)
    return doc
  } catch (err) {
    loggerHelper.logError('Error loading document: ', err.message)
  }
}

// Steps
export const createStep = async (step: JStep, documentId = '', options?) => {
  try {
    loggerHelper.logInfo('conceptor step createStep function')
    const currentUser = usersStore().user

    if (options?.fromTemplate) delete step.id

    step.client_id = currentUser.client_id
    step.site_id = options?.siteId || currentUser.site_id
    step.previous_version_step_id = step.id || ''
    step.document_id = documentId
    step.creation_date = new Date().getTime()
    step.temporary = false

    // If id is already generated through custom function, then create document with this id, else let firebase auto generate it
    const stepId =
      options?.newVersion || !step.id ? generateFirebaseLikeId() : step.id

    await dbHelper.setDataToCollection(
      STEPS_COLLECTION_NAME,
      stepId,
      removeInstance(step),
    )

    step.id = stepId

    return removeInstance(step)
  } catch (e) {
    console.error(e)
    loggerHelper.logError('Error in createStep', e.message)
  }
}

export const updateNewStepIds = async (steps: JStep[]) => {
  for (const step of steps) {
    if (step.branching?.length && step.id) {
      step.branching.forEach((branching: any) => {
        branching.action_details.steps_to_display =
          branching.action_details?.steps_to_display?.map((stepId) => {
            return (
              steps.find((s) => s.previous_version_step_id === stepId)?.id ||
              stepId
            )
          })
      })
      await dbHelper?.updateDataToCollection(
        STEPS_COLLECTION_NAME,
        step.id,
        removeInstance(step),
      )
    }
  }
}

export const updateStep = async (step: JStep) => {
  try {
    loggerHelper.logInfo('conceptor step updateStep function')
    const currentUser = usersStore().user
    step.client_id = currentUser.client_id
    step.site_id = currentUser.site_id
    step.update_date = new Date().getTime()

    await dbHelper.updateDataToCollection(
      STEPS_COLLECTION_NAME,
      step.id,
      removeInstance(step),
    )
  } catch (e) {
    console.error(e)
    loggerHelper.logError('Error in updateStep', e.message)
  }
}

export const setStep = async (step: JStep) => {
  try {
    loggerHelper.logInfo('conceptor step setStep function')
    const currentUser = usersStore().user
    step.client_id = currentUser.client_id
    step.site_id = currentUser.site_id
    step.update_date = new Date().getTime()

    await dbHelper.setDataToCollection(
      STEPS_COLLECTION_NAME,
      step.id,
      removeInstance(step),
    )
  } catch (e) {
    console.error(e)
    loggerHelper.logError('Error in setStep', e.message)
  }
}

export const validateStepKo = (
  valCell: any,
  step: any,
  indexCol?: number,
  isNotLastColumn = false,
  lastInputData?: JInputData | null | undefined,
) => {
  if (isUndefinedOrNullOrEmpty(valCell)) {
    if (!step.last_sampling_areas[indexCol]) return false
    return isNotLastColumn && step.is_mandatory
  }

  if (isDoubleCheckValid(step, lastInputData)) return true

  return isStepTypeKo(valCell, step, indexCol)
}

export const validateMandatoryStepFilled = (
  valCell,
  isMandatory,
  isActivated,
) => {
  return isUndefinedOrNullOrEmpty(valCell) && isMandatory && isActivated
}

export const isDoubleCheckValid = (
  step: JStep,
  lastInputData: JInputData | null | undefined,
) => {
  return (
    step.double_check && lastInputData && !lastInputData?.double_check_result
  )
}

export const isStepTypeKo = (valCell: any, step: any, indexCol?: number) => {
  const decimals = siteStore().site?.flags?.decimals

  if (
    [StepType.Text, StepType.Time, StepType.List, StepType.Checkbox].includes(
      step.type,
    )
  )
    return false

  if (step.type === StepType.Boolean)
    return valCell === false || valCell === 'false' || valCell === 'NOk'

  if (
    step.type === StepType.Measure ||
    (step.type === StepType.Calculator && step.is_measure)
  ) {
    const options = { step, cellValue: valCell, decimals, indexCol }

    return new CellMeasureValidation(ValidationType.ERROR).validate(options)
  }
}

export const validateStepBorderLine = (
  valCell: any,
  step: any,
  tolerance: number | undefined,
  indexCol: number,
) => {
  if (
    (step.type !== StepType.Measure &&
      !(step.type === StepType.Calculator && step.is_measure)) ||
    !valCell
  ) {
    return false
  }

  const options = { step, cellValue: valCell, tolerance, indexCol }

  return new CellMeasureValidation(ValidationType.WARNING).validate(options)
}

// Repetitions
export const getRepetitions = async (): Promise<any[]> => {
  return documentSettingsStore().filterDocumentSettings(
    DocumentSettingsType.repetition,
  )
}

export const getTags = async (): Promise<any[]> => {
  return documentSettingsStore().filterDocumentSettings(
    DocumentSettingsType.step_tag,
  )
}

export const createRepetition = async (
  repetition: JRepetition,
): Promise<any> => {
  const currentUser = usersStore().user
  repetition.client_id = currentUser.client_id
  repetition.site_id = currentUser.site_id
  // TO DO refacto => no need to create then get
  repetition.deleted_at = repetition.deleted_at || null
  const newRepetition = await dbHelper.addDataToCollection(
    DOCUMENT_SETTINGS_COLLECTION_NAME,
    repetition,
  )
  const newRepetitionCreated = await dbHelper.getDocFromCollection(
    DOCUMENT_SETTINGS_COLLECTION_NAME,
    newRepetition.id,
  )
  return newRepetitionCreated
}

export const getDocumentAttachment = async (attachment_id: string) => {
  const file = await dbHelper.getDocFromCollection(
    FILES_ATTACHMENT_COLLECTION_NAME,
    attachment_id,
  )
  return file
}

export const checkDuplicatesDocumentsByName = async (documentName: string) => {
  return await tCore.documents.getDocuments.query({
    search: documentName,
    bypassPagination: true,
  })
}
